.collapse-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
}

.collapse {
  width: 100%;
  background-color: #FF6060;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0 20px;
}
.collapse .arrow {
  transition: all 0.4s;
}
.collapse .arrow-up {
  transform: rotate(0deg);
}
.collapse .arrow-down {
  transform: rotate(-180deg);
}

.collapse-title {
  display: flex;
  font-size: 24px;
  font-weight: 700;
}

.collapse-content {
  display: flex;
  height: 100px;
  width: 80%;
  justify-content: flex-start;
  padding: 20px;
  background-color: #f6f6f6;
  border-radius: 5px;
  gap: 5px;
  color: black;
  visibility: visible;
  opacity: 1;
  transition-duration: 0.5s;
  transition-delay: 0.1s, 0s;
  transition-property: opacity, height;
  font-size: 20px;
  font-weight: 400;
}

.collapse-content-hidden {
  height: 0;
  width: 82%;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}

.ripple-container {
  position: relative;
  overflow: hidden;
}

.ripple {
  width: 50px;
  height: 50px;
  position: absolute;
  background: linear-gradient(27deg, rgb(190, 46, 4) 0%, rgb(255, 96, 96) 53%, rgb(255, 88, 0) 100%);
  display: block;
  content: "";
  border-radius: 50%;
  opacity: 1;
  animation: 1s ease-in 1 forwards ripple-effect;
}

@keyframes ripple-effect {
  from {
    transform: scale(0) rotate(0deg);
    opacity: 1;
  }
  to {
    transform: scale(99) rotate(360deg);
    opacity: 0;
  }
}/*# sourceMappingURL=Collapse.css.map */