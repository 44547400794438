header{
    height: 20%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    padding: 0;
    
    .nav-links{
        display: flex;
        flex-direction: row;
        gap: 50px;
        font-size: 24px;
        font-weight: 500;

        .active{
            border-bottom: 3px solid black;
        }
        li{
            gap: 30px;
            list-style: none;
        }
    
        a{
            text-decoration: none;
            color: black;
        }
    }
}

@media (max-width: 700px){
    header .nav-links{
        gap: 30px;
        font-size: 18px;
        text-transform: uppercase;
    }
}

@media (max-width: 550px){
    .kasa-logo{
        width: 30%;
    }
    header .nav-links{
        font-size: 16px;
    }
}