.banner2 {
    border: 1px solid black;
    aspect-ratio: 16/9;
    max-height: 25vh;
    width: 100%;
    overflow: hidden;
    border-radius: 25px;
    position: relative;
    margin-bottom: 50px;
    box-shadow: 0px 4px 50px 0px #00000040;
}

.banner2::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.335);
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 25px;
}

.banner2 img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 25px;
    object-position: center 55%;
}

@media (max-width: 1280px){
    .banner2{
        max-height: 22vh;
    }
}

@media (max-width: 700px){
    .banner2{
        max-height: 18vh;
        margin-bottom: 30px;
    }
}

@media (max-width: 550px){
    .banner2{
        max-height: 14vh;
    }
}