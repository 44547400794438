.card {
  aspect-ratio: 1/1;
  position: relative;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.5s ease-in-out;
}
.card::after {
  content: "";
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.card:hover img {
  transform: scale(1.1);
}
.card img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  transition: 0.5s ease-in-out;
  height: 100%;
}
.card h3 {
  z-index: 20;
  width: 80%;
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  font-weight: 700;
  font-size: 18px;
  color: white;
}

@media (max-width: 1280px) {
  .card h3 {
    font-size: 16px;
  }
  .card:hover img {
    transform: none;
  }
}
@media (min-width: 1280px) {
  h3::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: -30px;
    width: 0;
    height: 150%;
    background-color: rgba(255, 96, 96, 0.7803921569);
    z-index: -1;
    transition: 0.5s;
    border-radius: 5px;
  }
  .card:hover h3::after {
    width: 110%;
  }
}/*# sourceMappingURL=Card.css.map */