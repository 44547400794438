.slider {
  aspect-ratio: 16/9;
  position: relative;
  max-height: 60vh;
  width: 100%;
  overflow: hidden;
  border-radius: 25px;
  margin-bottom: 50px;
}

.slide-container {
  overflow: hidden;
  height: 100%;
}

.slide-container img {
  display: block;
  width: 100%;
}

.slider img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 150px;
  height: 150px;
  color: white;
  cursor: pointer;
}

.slider-arrow-left {
  left: 0;
}

.slider-arrow-right {
  right: 0;
}

.slideCount {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 20px;
  font-weight: 500;
  text-shadow: 1px 1px 5px #000000;
}

@media (max-width: 1280px) {
  .slider {
    margin-bottom: 0;
  }
}
@media (max-width: 700px) {
  .slider-arrow {
    height: 75px;
    width: 75px;
  }
  .slideCount {
    margin-bottom: 0;
    font-size: 16px;
  }
}
@media (max-width: 500px) {
  .slider-arrow {
    height: 45px;
    width: 45px;
  }
}/*# sourceMappingURL=Slideshow.css.map */