.banner {
    aspect-ratio: 16/9;
    height: 25vh;
    width: 100%;
    overflow: hidden;
    border-radius: 25px;
    position: relative;
    margin-bottom: 50px;
    box-shadow: 0px 4px 50px 0px #00000040;
}

.banner::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.585);
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 25px;
}

.banner img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 25px;
    object-position: center 55%;
}

.banner p {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2vw;
    font-weight: 700;
    z-index: 3;
    text-shadow: 0 0 8px black;
    margin: 0;
}
@media (max-width: 1500px){
    .banner p{
        font-size: 3vw;
    }
}
@media (max-width: 1280px){
    .banner{
        height: 15vh;
    }
    .banner p{
        font-size: 2.5vw;
    }
}
@media (max-width: 700px){
    .banner{
        margin-bottom: 25px;
        height: 12vh;
        border-radius: 10px;
    }
    .banner::after{
        border-radius: 10px;

    }
    .banner img{
        border-radius: 10px;
    }
    .banner p{
        font-size: 24px;
        left: 20px;
        transform: translate(0, -50%);
    }
    .banner .first-span{
        display: block;
    }
}
