.gallery {
  gap: 50px;
  background-color: #f6f6f6;
  padding: 50px;
  border-radius: 25px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 50px;
  box-sizing: border-box;
}

@media (max-width: 1280px) {
  .gallery {
    grid-template-columns: 1fr 1fr;
    gap: 25px;
  }
}
@media (max-width: 700px) {
  .gallery {
    grid-template-columns: 1fr;
    background-color: #fff;
    gap: 25px;
    padding: 0;
  }
}/*# sourceMappingURL=Gallery.css.map */