.about-main{
    width: 100%;
    margin: 0 auto;
}

.about-main-collapse{
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
}

@media (max-width: 700px){
    .about-main-collapse{
        margin-bottom: 30px;
    }
}