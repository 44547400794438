.logement-wrapper {
  width: 100%;
  overflow: hidden;
}
.logement-wrapper .logement-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.logement-wrapper .logement-left-info {
  justify-content: flex-start;
}
.logement-wrapper .logement-left-info h1 {
  font-size: 36px;
  font-weight: 500;
  color: #FF6060;
  margin-bottom: 0;
}
.logement-wrapper .logement-left-info p {
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 40px;
}
.logement-wrapper .logement-left-info .tags {
  display: flex;
  gap: 20px;
  color: white;
  font-weight: 700;
  font-size: 14px;
  height: 2em;
}
.logement-wrapper .logement-left-info .tags .tag {
  background-color: #FF6060;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  height: 2em;
  width: auto;
  padding: 0 15px;
}
.logement-wrapper .logement-right-info .host-div {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 25px;
}
.logement-wrapper .logement-right-info .host-div .host-name {
  display: flex;
  color: #FF6060;
  font-weight: 500;
  font-size: 18px;
  flex-direction: column;
  text-align: right;
}
.logement-wrapper .logement-right-info .host-div img {
  border-radius: 50%;
  height: 100px;
}
.logement-wrapper .logement-collapse {
  display: flex;
  width: 100%;
  gap: 200px;
  margin-bottom: 50px;
  box-sizing: border-box;
}
.logement-wrapper .logement-collapse .collapse-content {
  display: flex;
  flex-direction: column;
  min-height: 150px;
  height: auto;
  transition: 0.5s;
}
.logement-wrapper .logement-collapse .collapse-content p {
  margin: 0;
}
.logement-wrapper .logement-collapse .collapse-content-hidden p {
  margin: 0;
}
.logement-wrapper .description-collapse {
  width: 100%;
}
.logement-wrapper .equipment-collapse {
  width: 100%;
}

.collapse-container {
  width: 100%;
}
.collapse-container .collapse-content {
  width: 100%;
}
.collapse-container .collapse-content-hidden {
  width: 100%;
}

@media (max-width: 1280px) {
  .logement-info {
    display: flex;
    flex-direction: column;
  }
  .logement-info .logement-left-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .logement-info .logement-left-info p {
    margin-bottom: 0;
  }
  .logement-info .logement-right-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logement-info .logement-right-info .rating {
    height: 50px;
  }
  .logement-info .logement-right-info .host-div {
    order: 1;
  }
  .logement-collapse {
    flex-direction: column;
    gap: 50px !important;
  }
}
@media (max-width: 700px) {
  .logement-right-info {
    margin-top: 20px;
  }
  .host-div img {
    height: 20px;
  }
}
@media (max-width: 500px) {
  .logement-left-info .tag {
    font-size: 10px;
  }
}/*# sourceMappingURL=Logement.css.map */