footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  width: 100%;
  height: 20%;
  background-color: black;
  position: absolute;
  left: 0;
  right: 0;
}

footer p {
  color: white;
  font-size: 24px;
  font-weight: 500;
}

@media (max-width: 1280px) {
  footer {
    justify-content: center;
    gap: 0;
  }
  footer p {
    text-align: center;
    font-size: 20px;
  }
}
@media (max-width: 700px) {
  footer p {
    text-align: center;
    font-size: 14px;
  }
}/*# sourceMappingURL=Footer.css.map */